/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./variables";

html {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: #fff;

	*,
	::after,
	::before {
		box-sizing: border-box;
		scroll-behavior: smooth;
	}

	body {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		font-family: $font-stack-poppins;
		font-weight: 300;
		line-height: 1.563;
		font-size: 16px;
		color: #000;
		background-color: #fff;
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;

		#root {
			height: 100%;

			.App {
				height: 100%;
			}
		}

		input,
		textarea,
		button,
		select {
			line-height: 1.563;
			font-size: 16px;
			color: #000;
			font-weight: 300;
			-webkit-font-smoothing: antialiased;
			text-rendering: optimizeLegibility;
		}

		a {
			color: $link-color;

			&:link {
				color: $link-color;
				text-decoration: none;
			}

			&:visited {
				color: $link-visited-color;
				text-decoration: none;
			}

			&:hover {
				color: $link-hover-color;
				text-decoration: none;
			}
		}

		.navbar-nav,
		.lang-control {
			a {
				color: #000000;

				&:link {
					color: #000000;
					text-decoration: none;
				}

				&:visited {
					color: #000000;
					text-decoration: none;
				}

				&:hover {
					color: #000000;
					text-decoration: none;
				}
			}
		}

		.main-header-title {
			font-family: $font-stack-header;
			font-weight: 800;
			text-transform: uppercase;
		}

		.header-title {
			font-family: $font-stack-header;
			font-weight: 800;
			font-size: 1.25em;
			text-transform: uppercase;
		}

		.rounded-corners-wrapper {
			background-color: $rounded-corners-wrapper-background-color;
			border-radius: 8px;
			border: solid 1px $rounded-corners-wrapper-border-color;
		}
	}
}

.capitalize {
	text-transform: capitalize;
}

.card {
	box-shadow: 0px 3px 10px -10px black;
	border-radius: 10px;
	border: 0.5px solid #00000022;
	.card-header {
		border-radius: 10px 10px 0px 0px;
		border-bottom: none;
		&:only-child {
			border-radius: 10px;
		}
	}
}

.custom-control-input:active ~ .custom-control-label::before {
	background-color: #ccc !important;
	border: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
	background-color: black;
	border: none;
}
